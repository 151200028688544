<template>
  <div>
    <div class="flex align-items-center bd" v-show="obj.num>0">
      <div class="image" :style="`background:url(${obj.smallImg||productImg});background-size: cover;`"></div>
      <div class="content flex flex-direction justify-between">
        <div class="font-s14">{{obj.productName}}</div>
        <div class="font-s12"></div>
        <div class="amout">￥{{obj.normalPrice}}</div>
      </div>
      <div class="btn-d flex justify-end">
        <div class="btn btn-r" @click="handleReduce(obj)">
          <van-icon name="minus" />
        </div>
        <span>{{obj.num}}</span>
        <div class="btn btn-a">
          <van-icon name="plus" @click="handleAdd(obj)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productImg from '../assets/product.jpg'
export default {
  props: {
    obj: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      productImg: productImg,
    }
  },



  methods: {
    handleAdd (item) {
      item.num = parseInt(item.num) + 1
    },

    handleReduce (item) {
      if (item.num === 0) {
        return
      }
      item.num = parseInt(item.num) - 1
    },
  }

}
</script>

<style lang="less" scoped>
.bd {
  height: 5rem;
  border-bottom: 0.0625rem solid #fafafa;
  padding: 0 1.25rem;
  .image {
    background: #000;
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 0.3125rem;
    margin-right: 0.3125rem;

    overflow: hidden;
  }
  .content {
    height: 4.375rem;
    text-align: left;
    flex: 1;
    .amout {
      color: #ed5d54;
    }
  }
  .btn-d {
    display: flex;
    justify-content: space-between;
    width: 70px;
    .btn {
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      border-radius: 50%;
    }
    .btn-a {
      color: #fff;
      background: #ee0a24;
    }
    .btn-r {
      color: #ee0a24;
      background: #fff;
      border: 0.0625rem solid #ee0a24;
    }
  }
}
</style>