<template>
  <div class="page">
    <van-nav-bar title="点单" left-arrow @click-left="onClickLeft" />
    <van-loading v-show="loading" class="loading-d">加载中...</van-loading>
    <div v-show="!loading" class="flex flex-direction">
      <div class="title flex align-items-center bg-white" style="border-bottom:1px dotted #eee">
        <div class="adress">
          <van-icon name="location" />
        </div>
        <div class="flex1 title-r">
          <div class="font-s13">一口香煲仔饭</div>
          <div class="font-s12 color-gray">还原河西经来合肥经开区</div>
        </div>
      </div>

      <!-- <div class="bg-white tab-div">
        <van-tabs v-model="active" class="tab-nav">
          <van-tab title="点单"></van-tab>
          <van-tab title="优惠"></van-tab>
        </van-tabs>
      </div> -->

      <div class="content flex1 flex">
        <van-sidebar v-model="category" @change="getCategory">
          <!-- <van-sidebar-item title="煲仔饭" badge="5" /> -->
          <van-sidebar-item :title="item.categoryName" v-for="item in categoryList" :key="item.categoryCode" />
        </van-sidebar>
        <div class="content-r bg-white flex1">
          <div class="con">
            <van-card v-for="item in currProductList" :key="item.productCode" :price="item.normalPrice" :desc="item.des"
              :title="item.productName" :thumb="item.smallImg || productImg">
              <template #price>
                <span>￥</span><span class="font-s15">{{item.normalPrice}}</span>
              </template>
              <template #footer>
                <div class="flex justify-end">
                  <div class="btn btn-r" @click="handleReduce(item)" v-show="item.num > 0">
                    <van-icon name="minus" />
                  </div>
                  <div class="num" style="text-align: center; margin-right: 0.6rem" v-show="item.num > 0">
                    {{ item.num }}
                  </div>
                  <div class="btn btn-a">
                    <van-icon name="plus" @click="handleAdd(item)" />
                  </div>
                </div>
              </template>
            </van-card>
          </div>
        </div>
      </div>
      <div class="bot-d" />

      <div class="foot">
        <div class="left flex">
          <div class="icon-d" @click="handleDetail">
            <van-badge :content="totalNum" class="tag" />
            <van-icon name="shopping-cart" />
          </div>
          <div>
            <span style="color: #ccc">￥</span>
            <span class="total">{{ totalAmount }}</span>
          </div>
        </div>
        <div class="right" @click="jump">点好了</div>
      </div>

      <van-action-sheet v-model="detailShow" title="已点商品列表">
        <div class="detail flex flex-direction">
          <div>
            <div v-show="totalNum === 0">您的购物车还是空的！</div>
            <div>
              <template v-if="checkedProductList.length > 0">
                <proDetail v-for="item in checkedProductList" :key="item.id" :obj="item" />
              </template>
            </div>
          </div>
          <div class="bot-d" />
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import proDetail from "../../components/productDetail.vue";
import { mapState } from "vuex";
import productImg from "../../assets/product.jpg";
export default {
  computed: {
    ...mapState(["memberCode"]),
  },

  components: {
    proDetail,
  },
  data () {
    return {
      productImg: productImg,
      loading: false,
      detailShow: false,
      active: 0,
      category: 0,
      categoryList: [],
      currProductList: [],
      checkedProductList: [], //已选择过的商品
    };
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1);
    },

    handleAdd (item) {
      item.num = parseInt(item.num) + 1;
      this.handldCheckedProductList(item, true);
    },

    handleReduce (item) {
      item.num = parseInt(item.num) - 1;
      this.handldCheckedProductList(item, false);
    },

    handldCheckedProductList (item, isAdd) {
      //处理已经选择的
      let isExist = this.checkedProductList.some((e) => {
        return e.productCode === item.productCode;
      });
      if (isAdd) {
        if (!isExist) {
          this.checkedProductList.push(item);
        }
      } else {
        if (item.num === 0) {
          this.checkedProductList.forEach((e, index) => {
            if (item.productCode === e.productCode) {
              this.checkedProductList.splice(index, 1);
            }
          });
        }
      }
    },

    getCategory (e) {
      // 点击当前分类 获取当前商品
      let temp = this.categoryList[e].categoryCode;
      this.currProductList = [];
      this.currProductList = this.productList.filter((item) => {
        return item.categoryCode === temp;
      });
    },

    handleDetail () {
      // 购物车详情
      this.detailShow = true;
    },

    getList () {
      this.loading = true;
      this.$http
        .post("merchantProduct/queryProductList", {
          merchantCode: "10000098",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            let temp = res.data.data;
            localStorage.setItem(
              "categoryList",
              JSON.stringify(temp.categoryList)
            );
            localStorage.setItem(
              "productList",
              JSON.stringify(temp.productList)
            );
            this.categoryList = temp.categoryList;
            this.productList = temp.productList;
            this.productList.forEach((item) => {
              item.num = 0;
            });
            this.getCategory(0);
          }
        });
    },

    jump () {
      this.$router.push({
        path: "/orderDetail",
      });
      let temp = this.checkedProductList.filter(item => {
        return item.num !== 0
      })
      sessionStorage.setItem('orderList', JSON.stringify(temp))
    },

    scrollTop () {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
    }
  },

  created () {
    this.getList();
    // let temp = sessionStorage.getItem('orderList')

    // this.checkedProductList = temp ? JSON.parse(temp) : []

    // this.categoryList = JSON.parse(localStorage.getItem("categoryList"));
    // this.productList = JSON.parse(localStorage.getItem("productList"));
    // this.productList.forEach((item) => {
    //   item.num = 0;
    // });
    // this.getCategory(0);
  },
  mounted () {
    window.addEventListener('scroll', this.scrollTop, true)
  },
  computed: {
    totalNum () {
      //总数量
      let temp = 0;
      this.checkedProductList.forEach((item) => {
        temp += parseInt(item.num);
      });
      return temp;
    },

    totalAmount () {
      //总金额
      let temp = 0;
      this.checkedProductList.forEach((item) => {
        temp += parseInt(item.num) * item.normalPrice;
      });
      return temp;
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.windowScroll)
  }
};
</script>

<style lang="less" scoped>
.page {
  height: 100vh;
  position: relative;
}
.title {
  height: 3.125rem;
  .title-r {
    text-align: left;
  }
}
.adress {
  width: 3.125rem;
}
.tab-div {
  margin: 0.3125rem 0 0.625rem;
  .tab-nav {
    width: 40%;
  }
}
.content {
  .con {
    text-align: left;
    padding: 0.1875rem;
    .van-card {
      background: #fff;
      border-radius: 0.3125rem;
      box-shadow: 2px 2px 5px #eee;
    }
    .btn {
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      border-radius: 50%;
    }
    .btn-a {
      color: #fff;
      background: #ee0a24;
    }
    .btn-r {
      margin-right: 1.25rem;
      color: #ee0a24;
      background: #fff;
      border: 0.0625rem solid #ee0a24;
    }
    .num {
      line-height: 1.25rem;
      text-align: center;
      margin-right: 0.6rem;
    }
  }
}
.foot {
  z-index: 99999;
  display: flex;
  position: fixed;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 2.8125rem;
  border-radius: 1.4375rem;
  background: #333;
  padding-left: 0.9875rem;
  line-height: 2.8125rem;
  .left {
    flex: 1;
    .icon-d {
      position: relative;
      top: -0.525rem;
      color: #fff;
      font-size: 1.25rem;
      height: 2.8125rem;
      width: 2.8125rem;
      border-radius: 50%;
      background: #ed5d54;
      .tag {
        position: absolute;
        top: 0;
        right: 0.125rem;
      }
    }
    .total {
      font-size: 1.125rem;
      font-weight: 700;
      color: #ed5d54;
    }
  }
  .right {
    color: #fff;
    width: 6.25rem;
    background: #ed5d54;
    font-size: 0.875rem;
    border-radius: 0 1.4375rem 1.4375rem 0;
  }
}
.loading-d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bot-d {
  height: 4.0625rem;
}
/deep/.van-card__title {
  font-weight: bold;
  font-size: 0.9rem;
}
/deep/.van-card__num {
  font-size: 1rem;
  color: #333;
}
/deep/.van-card__price {
  color: #ee0a24;
}
/deep/.content .con .btn-r {
  margin-right: 0.6rem;
}
/deep/.van-card__footer {
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
}
</style>